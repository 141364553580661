<template>
  <a-menu class="account-dropdown">
      <a-menu-item key="1" @click="goToProfile"  class="account-dropdown-item">
        <a class="link">
          <i class="icon icon-account_circle"></i>
          Your Profile
        </a>
      </a-menu-item>
      <!-- Switch Account Start -->
      <a-collapse v-model:activeKey="activeKey" accordion expand-icon-position="right" class="switch-account-collapse">
        <a-collapse-panel key="1" :header="panelHeader">
          <a-menu-item v-for="linkedAccount in linkedAccounts.filter(account=>account.id !== authUserInformation?.id)" :key="linkedAccount?.id" class="account-dropdown-item">
            <a class="link switch-user" @click.stop="switchUser(linkedAccount?.id)">
                <div class="switch-user__image">
                  <img
                    :src="linkedAccount?.profile_picture ?? ''"
                    alt="Icon"
                  />
                </div>
                <div class="switch-user__details">
                  <h4>{{linkedAccount?.name }}</h4>
                  <p>{{ linkedAccount?.email }}</p>
                </div>
                <button class="switch-user__close-btn" @click.stop="openAccountRemoveModal(linkedAccount)">
                  <i class="icon icon-add_plus"></i>
                </button>
            </a>
          </a-menu-item>
          <a-menu-item key="3" class="account-dropdown-item" @click="toggleLoginModal()">
            <a class="link">
              <div class="add-icon"><i class="icon-add_plus"></i></div>
              Add another account
            </a>
          </a-menu-item>
        </a-collapse-panel>
      </a-collapse>
      <!-- Switch Account End -->
      <a-menu-item key="4"  v-if="showOnSubscription" @click="redirectToSettings"  class="account-dropdown-item">
        <a class="link">
          <i class="icon icon-settings_filled"></i>
          Settings
        </a>
      </a-menu-item>
      <a-menu-item key="5" @click="logout" class="account-dropdown-item">
        <a class="link">
          <img
            class="icon icon-logout"
            src="../../assets/icons/logout.svg"
          />
          Logout
        </a>
      </a-menu-item>
      <AccessModal
        :visible="visibleAccessModal"
        :type="modalType"
        @closeAccessModal="toggleLoginModal"
        @showForgotPasswordModal="showForgotModalPass"
        @showOTPVerificationModal="()=>{this.OtpVerificationModalVisible = true}"
        :isAddAccount="true"
      />
      <ForgotPasswordModal 
        :visible="forgotModalVisible"
        @cancel="forgotModalVisible=false"
        :footer="null"
      />
      <OtpVerificationModal
        :visible="OtpVerificationModalVisible"
        @close="OtpVerificationModalVisible=false" 
        :isAddAccount="true"
      />
      <delete-modal
        :visible="removeAccountModalVisible"
        @handle-close="removeAccountModalVisible = false"
        title="Are you sure you want to remove this?"
        confirmText="Yes, Remove"
        cancelText="No, Don't Remove"
        @delete-record="removeAccount"
        :loading="deleteloading"
      ></delete-modal>
  </a-menu>
</template>
<script>
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex/dist/vuex.cjs.js";
import AccessModal from "../Auth/AccessModal.vue";
import OtpVerificationModal from "../Auth/OtpVerificationModal.vue";
import ForgotPasswordModal from "../Auth/ForgotPasswordModal.vue";
import DeleteModal from "./DeleteModal.vue";

export default {
  props: {
    showOnSubscription:{
      type: Boolean,
      default: true
    }
  },
  components:{
    AccessModal,
    OtpVerificationModal,
    ForgotPasswordModal,
    DeleteModal
  },
  data: () =>({
      activeKey: '1',
      visibleAccessModal: false,
      modalType: "",
      forgotModalVisible: false,
      OtpVerificationModalVisible: false,
      removeAccountModalVisible: false,
      activeUser: {},
      deleteloading:false
  }),
  computed:{
    ...mapGetters({
      authUserInformation: 'profile/userInformation',
      linkedAccounts: 'auth/linkedAccounts',
    }),
    userId(){
      return this.$store.getters['auth/getUserId'];
    },
    getUserInfo(){
      return this.$store.getters["profile/getSelectedUserProfile"];
    },
    panelHeader() {
      return this.activeKey === "1"
        ? "Hide more accounts"
        : "Show more accounts";
    },
  },
  async created(){
    await this.getLinkedAccounts()
  },
  methods:{
    ...mapActions({
      setSelectedUser: 'profile/setSelectedUser',
      getLinkedAccounts: 'auth/getLinkedAccounts',
      activateAccount: 'auth/activateAccount',
      addLinkedAccount: 'auth/addLinkedAccount',
      removeLinkedAccount: 'auth/removeLinkedAccount',
      
    }),
    async logout(){
      try{
        const accessToken = Cookies.get('access_token')
        Cookies.remove("access_token");
        this.$router.replace('/');
        await this.$store.dispatch('auth/logout', accessToken)
        .then(()=>{
          Cookies.remove("refresh_token");
          Cookies.remove("user_id");
          Cookies.remove("interests")
          Cookies.remove("user")
          this.$store.reset()
        })
      }
      catch(err){
        console.log(err.response.data);
      }
    },
    goToProfile() {
      this.setSelectedUser({...this.authUserInformation.profile, id:this.authUserInformation.id})
      this.$router.push({
        path: `/user/${this.authUserInformation?.profile?.username}/`
      });
      // this.getUserInformation()
    },
    async getUserInformation() {
      
      try {
        await this.$store.dispatch("profile/fetchUserProfile");
        await this.$store.dispatch("profile/loadExperiences");
        await this.$store.dispatch("profile/getAddedLanguages");
        await this.$store.dispatch("profile/fetchPhotoResume");
        
      } catch (err) {
        console.log(err);
      }
    },
    async redirectToSettings(){
      this.setSelectedUser({...this.authUserInformation.profile, id:this.authUserInformation.id})
      await this.$store.dispatch("profile/fetchUserProfile");
      this.$router.push({
        path: `/user/${this.authUserInformation?.profile?.username}/settings`
      });
    },
    toggleLoginModal(){
        this.visibleAccessModal = !this.visibleAccessModal
        this.modalType = this.visibleAccessModal ? 'login' : ''
    },
    showForgotModalPass() {
      this.forgotModalVisible = true
      this.toggleLoginModal();
    },
    async switchUser(userId){
      try{
        const linkedAccount = {
            name: this.authUserInformation?.profile?.full_name,
            email: this.authUserInformation?.email,
            profile_picture: this.authUserInformation?.profile?.profile_picture,
            id: this.authUserInformation?.id
          }
        await this.addLinkedAccount(linkedAccount)
        await this.activateAccount(userId)
        if (this.$route.fullPath === '/discover'){
          window.location.reload()
        }
        else{
          this.$router.push({path:'/discover'})
        }
      }
      catch(err){
        this.$showToast("Something went wrong. Please try again later.")
      }

    },
    async removeAccount(user){
      this.deleteloading = true;
      try{
        await this.removeLinkedAccount(this.activeUser?.id)
        this.$showToast({message:'Account removed successfully.', type:'success'})
        this.removeAccountModalVisible = false;
      }
      catch(err){
        console.log(err, "ERROR")
        this.$showToast({message:'Something went wrong. Please try again later', type: 'failure'})
        this.removeAccountModalVisible = false;
      }
      finally{
        this.deleteloading = false;
      }
    },
    openAccountRemoveModal(user) {
      this.removeAccountModalVisible = true;
      this.activeUser = user;
    }
    
  }
};
</script>
<style lang="scss">
.ant-dropdown {
  .account-dropdown {
    .account-dropdown-item {
      .ant-dropdown-menu-title-content {
        .link {
          padding: 1.1rem 2rem !important;
          .add-icon {
            width: 2.7rem;
            height: 2.7rem;
            border-radius: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #d9d9d9b7;
            margin-right: 1rem;
            i {
              font-size: 1.2rem;
            }
          }
          .icon {
            color: $color-primary;
            font-size: 2.8rem;
            margin-right: 1rem;
          }
        }
        .switch-user {
          display: flex;
          align-items: center;
          gap: 1rem;
          &__image {
            width: 2.7rem;
            height: 2.7rem;
            border-radius: 100%;
            overflow: hidden;
            background-color: #d9d9d9b7;
            img {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }
          &__details {
            display: flex;
            flex-direction: column;
            gap: 0.2rem;
            h4 {
              font-size: 1.4rem;
              font-family: $font-primary-medium;
              margin-bottom: 0;
              line-height: 1.8rem;
              color: $color-black;
            }
            p {
              font-size: 1.2rem;
              font-family: $font-primary;
              margin-bottom: 0;
              line-height: 1.6rem;
              color: $color-black;
              opacity: 0.7;
            }
          }
          &__close-btn {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 100% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #d9d9d9b7;
            border: none;
            outline: none;
            margin-left: auto;
            cursor: pointer;
            .icon {
              transform: rotate(45deg);
              display: block;
              color: $color-black;
              font-size: 1rem;
              margin: 0.1rem 0.1rem 0 0;
            }
            &:hover {
              background-color: #d9d9d993;
            }
          }
        }
      }
    }
    .switch-account-collapse {
      border: none;
      border-bottom: 1px solid rgba(128, 128, 128, 0.3);
      background-color: $color-white;
      min-width: 24rem;
      .ant-collapse-item {
        border: none;
        .ant-collapse-header {
          padding: 1.1rem 2.2rem !important;
          font-size: 1.6rem;
          font-family: $font-primary-medium;
          margin-bottom: 0;
          line-height: 2.2rem;
          color: $color-black;
          background-color: transparent;
          border-radius: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          opacity: 1;
          gap: 2rem;
          .anticon {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #d9d9d9b7;
            position: relative;
            order: 2;
            right: 0;
            transform: none;
            svg {
              font-size: 1.2rem;
              color: $color-black;
              opacity: 1;
              transform: rotate(90deg);
            }
          }
        }
        .ant-collapse-content {
          border: none;
          background-color: rgba(128, 128, 128, 0.068);
          .ant-collapse-content-box {
            padding: 0;
            .account-dropdown-item {
              border-top: 1px solid rgba(128, 128, 128, 0.3);
            }
          }
        }
      }
      .ant-collapse-item-active {
        .ant-collapse-header {
          .anticon {
            svg {
              transform: rotate(-90deg) !important;
            }
          }
        }
      }
    }
  }
}
.icon-logout {
  color: #be0000;
  fill: #be0000;
  filter: invert(13%) sepia(94%) saturate(7466%) hue-rotate(0deg)
    brightness(94%) contrast(115%);
  margin: 0 1.2rem 0 0.2rem;
  width: 2.5rem;
}
</style>